import {request} from "../../../util/Request";
import moment from "moment-timezone";
import Error from "../../../util/Error";
import {mapState} from "vuex";

const DOCUMENT_STATE = {
    id: null,
    comments: null,
    created_at: null,
    status: null,
    _method: 'post',
}

const FORM_STATE = {
    id: null,
    comments: null,
    created_at: null,
    status: null,
    document: {...DOCUMENT_STATE},
    documents: [],
    _method: 'post',
}

export default {
    data() {
        return {
            formFields: {...FORM_STATE},
            formErrors: new Error({}),
            selectedLoad: null
        }
    },
    methods: {
        handleInTransitClick(record) {
            this.formFields.status = 6
            this.formFields.edit = 0
            this.formFields.created_at = moment()
            this.formFields.id = record.id
            this.selectedLoad = record
        },
        handleUnLoadClick(record) {
            this.formFields.status = 7
            this.formFields.edit = 0
            this.formFields.created_at = moment()
            this.formFields.id = record.id
            this.formFields.document = {...DOCUMENT_STATE}
            this.formFields.documents = []
            this.selectedLoad = record
        },
        handleAcceptClick(record) {
            this.formFields.status = 4
            this.formFields.edit = 0
            this.formFields.created_at = null
            this.formFields.id = record.id
            this.selectedLoad = record
        },
        handleAcceptedEditClick(record) {
            this.formFields.status = 4 // just temporary
            this.formFields.created_at = null // just temporary
            this.formFields.edit = 1
            this.formFields.id = record.id
            this.formFields.driver_id = record.driver.id
            this.formFields.transport_vehicle_id = record.transport_vehicle.id
            this.formFields.from_estimated_loading_time = record.from_estimated_loading_time ? moment(this.$global.utcDateToLocalDate(record.from_estimated_loading_time, 'YYYY-MM-DD HH:mm:ss')) : null
            this.formFields.to_estimated_loading_time = record.to_estimated_loading_time ? moment(this.$global.utcDateToLocalDate(record.to_estimated_loading_time, 'YYYY-MM-DD HH:mm:ss')) : null
            this.formFields.estimated_unloading_time = record.estimated_unloading_time ? moment(this.$global.utcDateToLocalDate(record.estimated_unloading_time, 'YYYY-MM-DD HH:mm:ss')) : null
            this.selectedLoad = record
        },
        handleReconfirmClick(record) {
            this.formFields.status = 4 // just temporary
            this.formFields.created_at = null // just temporary
            this.formFields.supplier_carrier_reconfirm_at = 1
            this.formFields.edit = 1
            this.formFields.id = record.id
            this.formFields.driver_id = record.driver.id
            this.formFields.transport_vehicle_id = record.transport_vehicle.id
            this.formFields.from_estimated_loading_time = record.from_estimated_loading_time ? moment(this.$global.utcDateToLocalDate(record.from_estimated_loading_time, 'YYYY-MM-DD HH:mm:ss')) : null
            this.formFields.to_estimated_loading_time = record.to_estimated_loading_time ? moment(this.$global.utcDateToLocalDate(record.to_estimated_loading_time, 'YYYY-MM-DD HH:mm:ss')) : null
            this.formFields.estimated_unloading_time = record.estimated_unloading_time ? moment(this.$global.utcDateToLocalDate(record.estimated_unloading_time, 'YYYY-MM-DD HH:mm:ss')) : null
            this.selectedLoad = record
        },
        handleRejectClick(record) {
            this.formFields.status = 3
            this.formFields.created_at = null
            this.formFields.id = record.id
            this.selectedLoad = record
        },
        async handleInboundLoadResetClick(record) {
            if(this.global.pendingRequests > 0) {
                return false;
            }

            try {
                const response = await request({
                    url: '/inbound/loads/carrier/supplier/reset',
                    method: 'post',
                    data: {
                        id: record.id
                    },
                })

                this.itemUpdated()
                this.loadList()
            } catch (error) {
                if (error.request && error.request.status && error.request.status === 422) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                    return false
                }

                this.handleServerError(error)
            }
        },
        handleAddUpdateDocumentClick() {
            this.formFields.document.error = false;
            this.formErrors = new Error({})
            let errors = {};

            if (!this.formFields.document.document || this.formFields.document.document.length <= 0 || !this.formFields.document.document.id) {
                this.formFields.document.error = true;
                errors.document = [this.$t('validation.required')];
            }

            if (!this.formFields.document.title || _.trim(this.formFields.document.title).length <= 2) {
                this.formFields.document.error = true;
                errors.title = [this.$t('validation.required')];
            }

            _.map(this.formFields.documents, (document) => {
                if (document.token !== this.formFields.document.token) {
                    if (document.title === this.formFields.document.title) {
                        errors.title = [this.$t('validation.duplicate')];
                        this.formFields.document.error = true;
                        this.formErrors = new Error(errors);
                        return false;
                    }
                }
            });

            this.formErrors = new Error(errors)
            if (this.formFields.document.error) return false;

            const entity = this.formFields.document;
            let index = -1;
            if (entity.token)
                index = _.findIndex(this.formFields.documents, {token: entity.token});

            if (this.formFields.documents[index] !== undefined) {
                this.formFields.documents[index] = entity;
            } else {
                entity.token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                this.formFields.documents.push(entity)
            }

            this.formFields.document = {
                ...DOCUMENT_STATE,
                token: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
            }
        },
        handleDeleteDocumentClick(token) {
            const index = _.findIndex(this.formFields.documents, {token: token})
            if (this.formFields.documents[index] !== undefined) {
                this.formFields.documents.splice(index, 1);
            }
        },
        handleEditDocumentClick(token) {
            const index = _.findIndex(this.formFields.documents, {token: token})
            if (this.formFields.documents[index] !== undefined) {
                this.formFields.document = {...this.formFields.documents[index]};
            }
        },
        resetDocument() {
            this.formFields.document = {...DOCUMENT_STATE}
            this.formFields.document.error = false;
        },
    },
    computed: {
        ...mapState([
            'global',
        ]),
        title() {
            if (this.formFields.status == 6) {
                return this.$t('title.loadInTransit')
            } else if (this.formFields.status == 7) {
                return this.$t('title.loadUnloaded')
            } else if (this.formFields.status == 4) {
                return this.selectedLoad.does_m_l_approval_required ? this.$t('title.acceptEarlyTransportOrder') : this.$t('title.acceptLoad')
            } else if (this.formFields.status == 3) {
                return this.$t('title.rejectLoad')
            }
        }
    }
}
