import {request} from "../../../util/Request";
import qs from "qs"

export default {
    data() {
        return {
            exportModal: {
                id: null,
                visibility: false,
                columns: [
                    {id: 'load_number', label: this.$t('title.loadNumber'), show: true},
                    {id: 'supplier_compound', label: this.$t('title.supplierCompound'), show: true},
                    {id: 'from_location', label: this.$t('title.fromLocation'), show: true},
                    {id: 'to_location', label: this.$t('title.toLocation'), show: true},
                    {id: 'driver', label: this.$t('title.driver'), show: true},
                    {id: 'transport_vehicle', label: this.$t('title.transportVehicle'), show: true},
                    {id: 'qty', label: this.$t('title.qty'), show: true},
                    {id: 'status', label: this.$t('title.status'), show: true},
                ],
                headers: {
                    [this.$t('column.loadNumber')]: 'load_number',
                    [this.$t('column.supplierCompound')]: 'supplier_compound',
                    [this.$t('column.fromLocation')]: 'from_location',
                    [this.$t('column.toLocation')]: 'to_location',
                    [this.$t('column.driver')]: 'driver',
                    [this.$t('column.transportVehicle')]: 'transport_vehicle',
                    [this.$t('column.qty')]: 'qty',
                    [this.$t('column.status')]: 'status',
                }
            },
        }
    },
    methods: {
        async handleSingleExcelExportSubmitClick() {
            let columns = _.filter(this.exportModal.columns, (item) => item.show).map(item => item.id)
            const headers = {}
            _.map(this.exportModal.headers, (index, item) => {
                if (index && _.includes(columns, index)) {
                    headers[item] = index
                }
            })
            this.exportModal.headers = headers

            const response = await request({
                method: "get",
                url: `inbound/loads/carrier/supplier/excel/detail/${this.exportModal.id}`,
            })

            const {data} = response
            const rows = [];
            const row = _.pick({
                load_number: this.$global.val(data.load_number),
                supplier_compound: this.$global.val((data.supplier_compound || {}).name),
                from_location: this.$global.val((data.from_location || {}).label),
                to_location: this.$global.val((data.to_location || {}).label),
                driver: this.$global.val((data.driver || {}).name),
                transport_vehicle: (data.transport_vehicle ? (data.transport_vehicle.truck_plate_number ? data.transport_vehicle.truck_plate_number : "") + (data.transport_vehicle.trailer_plate_number ? "|" + data.transport_vehicle.trailer_plate_number : "") : ""),
                loading_factors: this.$global.val(data.loading_factors),
                qty: this.$global.val(data.qty),
                loading_date: (data.from_estimated_loading_time ? this.$global.val(this.$global.utcDateToLocalDate(data.from_estimated_loading_time)) : "") + "" + (data.to_estimated_loading_time ? +"-" + this.$global.utcDateToLocalDate(data.to_estimated_loading_time) : ""),
                status: this.$global.val(data._status),
                updated_at: this.$global.val(this.$global.utcDateToLocalDate(data.updated_at)),
            }, columns)
            rows.push(row)
            return rows;
        },
        handleExcelExportClick(id) {
            this.exportModal.id = id
            this.exportModal.visibility = true
        },
        async handleExcelExportSubmitClick() {
            let columns = _.filter(this.exportModal.columns, (item) => item.show).map(item => item.id)
            const headers = {}
            _.map(this.exportModal.headers, (index, item) => {
                if (index && _.includes(columns, index)) {
                    headers[item] = index
                }
            })
            this.exportModal.headers = headers

            const extraParams = this.getExtraParams() // from listingMixin
            let newListQueryParams = this.listQueryParams // from listingMixin
            const params = {...newListQueryParams, ...extraParams}
            const response = await request({
                method: "get",
                url: 'inbound/loads/carrier/supplier/excel',
                params: params,
                paramsSerializer: ((params) => qs.stringify(params)),
            })

            const {data} = response
            const rows = _.map(data, (item, index) => _.pick({
                load_number: this.$global.val(item.load_number),
                supplier_compound: this.$global.val((item.supplier_compound || {}).name),
                from_location: this.$global.val((item.from_location || {}).label),
                to_location: this.$global.val((item.to_location || {}).label),
                driver: this.$global.val((item.driver || {}).name),
                transport_vehicle: (item.transport_vehicle ? (item.transport_vehicle.truck_plate_number ? item.transport_vehicle.truck_plate_number : "") + (item.transport_vehicle.trailer_plate_number ? "|" + item.transport_vehicle.trailer_plate_number : "") : ""),
                qty: this.$global.val(item.qty),
                status: this.$global.val(item._status),
            }, columns))
            return rows;
        },
        handleExcelExportCancelClick() {
            this.exportModal.visibility = false
            this.exportModal.id = null
        },
        handleExcelExportSelectAllClick() {
            _.map(this.exportModal.columns, (item, index) => {
                this.exportModal.columns[index].show = true
            })
        },
        handleExcelExportUnSelectAllClick() {
            _.map(this.exportModal.columns, (item, index) => {
                this.exportModal.columns[index].show = false
            })
        },
    }
}
