<template>
    <div v-if="show">
        <div class="card" v-show="operation !== 'detail'">
            <div class="card-header card-header-flex pb-2">
                <div class="w-100 mt-2">
                    <div class="row">
                        <div class="col-8">
                            <h5 class="mt-3 ml-0 mr-3 mb-2">
                                <strong>
                                    <template v-if="operation === null">{{$t('title.inbound')}}</template>
                                    <template v-else>{{ $t(operationTitle) }}</template>
                                </strong>
                            </h5>
                        </div>
                        <div class="col-4 text-right">
                            <div v-if="operation === null">
                                <div class="mt-3">
                                    <!-- Using components -->
                                    <b-input-group class="mt-3">
                                        <b-form-input type="search" class="form-control form-control-sm"
                                                      :placeholder="$t('input.whatAreYouLookingFor')"
                                                      v-on:keyup.enter="handleSearch" v-model="search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                      variant="outline-info"
                                                      @click="filters.visible = !filters.visible" v-b-tooltip.hover
                                                      v-if="$global.hasPermission('inboundloadsownview')">
                                                <i class="fa fa-filter"></i>
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.exportExcel')"
                                                      variant="outline-info"
                                                      v-if="$global.hasPermission('inboundloadsownview')"
                                                      @click="handleExcelExportClick" v-b-tooltip.hover>
                                                <i class="fa fa-file-excel-o"></i>
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.resetList')"
                                                      variant="outline-info"
                                                      @click="handleResetFilterClick()" v-b-tooltip.hover>
                                                <i class="fa fa-refresh"></i>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </div>
                            </div>
                            <div v-else>

                            </div>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.w-100 -->
            </div><!-- /.card-header -->
            <div class="card-body">
                <div class="supplier-inbound-table">
                    <b-table hover responsive
                             ref="table"
                             :busy="listingLoading"
                             :items="dataSource"
                             :fields="columns"
                             :sort-by.sync="sortField"
                             :tbody-tr-class="rowClass"
                             @sort-changed="handleSortChange">
                        <template v-slot:cell(supplier_compound_id)="record">
                            {{record.item.supplier_compound ? record.item.supplier_compound.name : ''}}
                        </template>
                        <template v-slot:cell(from_location)="{item}">
                            <span v-if="item.from_location">
                                <print-ad :item="item.from_location" titled="1"></print-ad>
                            </span>
                        </template>
                        <template v-slot:cell(to_location)="{item}">
                            <span v-if="item.to_location">
                                <print-ad :item="item.to_location" titled="1"></print-ad>
                            </span>
                        </template>
                        <template v-slot:cell(transport_vehicle_id)="record">
                            <span v-if="record.item.transport_vehicle">
                                <span v-if="record.item.transport_vehicle.truck_plate_number"> {{ record.item.transport_vehicle.truck_plate_number }} </span>
                                <span v-if="record.item.transport_vehicle.trailer_plate_number"> | {{ record.item.transport_vehicle.trailer_plate_number }} </span>
                            </span>
                        </template>
                        <template v-slot:cell(driver_id)="record">
                            {{record.item.driver ? record.item.driver.name : ''}}
                        </template>
                        <template v-slot:cell(orders)="record">
                            {{record.item.inbound_load_orders ? record.item.inbound_load_orders.length : 0}}
                        </template>
                        <template v-slot:cell(status)="record">
                            <ibl-status :label="record.item.status == 4 && record.item.supplier_carrier_reconfirm_at ? 'Confirmed' : false" :status="record.item.status"></ibl-status>
                            <template v-if="record.item.status <= 2 && record.item.does_m_l_approval_required"><br>
                                <b-badge variant="danger" :title="$t('title.earlyTransportOrder')" v-b-tooltip.hover>
                                    {{$t('title.earlyTransportOrder')}}
                                </b-badge>
                            </template>
                            <template
                                v-if="record.item.status >=4 && record.item.status <= 5 && (record.item.does_m_l_approval_required > 0 && !record.item.manufacturer_approval_accepted_at)">
                                <br>
                                <b-badge variant="warning" :title="$t('title.waitingFcaApproval')" v-b-tooltip.hover>
                                    {{$t('title.waitingFcaApproval')}}
                                </b-badge>
                            </template>
                        </template>
                        <template v-slot:cell(action)="record">
                            <div v-if="$global.hasRole('suppliercarrier')">
                                <a @click="handleAcceptClick(record.item)"
                                   v-if="record.item.status <= 2" :title="$t('button.title.acceptLoad')"
                                   v-b-tooltip.hover class="text-success">
                                    <i class="fe fe-check-square"></i>
                                </a>
                                <a-popconfirm title="Are you sure？" @confirm="handleInboundLoadResetClick(record.item)"
                                    v-if="record.item.status === 4 && !record.item.had_reset && !record.item.edited">
                                    <a :title="$t('button.title.reset')"
                                       v-b-tooltip.hover class="text-danger ml-2">
                                        <i class="fe fe-refresh-ccw"></i>
                                    </a>
                                </a-popconfirm>
                                <a @click="handleAcceptedEditClick(record.item)"
                                   v-if="!_.isEmpty(record.item.supplier_carrier_reconfirm_at) && record.item.status >= 4 && record.item.status < 6 && !record.item.edited"
                                   :title="$t('button.title.editItem')"
                                   v-b-tooltip.hover class="ml-2">
                                    <i class="fe fe-edit"></i>
                                </a>
                                <a @click="handleRejectClick(record.item)"
                                   v-if="record.item.status <= 2" :title="$t('button.title.rejectLoad')"
                                   v-b-tooltip.hover class="ml-2 text-danger">
                                    <i class="fe fe-x-square"></i>
                                </a>
                                <a @click="handleReconfirmClick(record.item)"
                                   v-if="record.item.does_m_l_approval_required > 0 && record.item.manufacturer_approval_accepted_at &&
                                      _.isEmpty(record.item.supplier_carrier_reconfirm_at) && record.item.status == 4"
                                   :title="$t('button.title.transportOrder')"
                                   v-b-tooltip.hover class="ml-2">
                                    <i class="fe fe-check-square"></i>
                                </a>
                                <template
                                    v-if="!_.isEmpty(record.item.supplier_carrier_reconfirm_at)
                                    && (record.item.does_m_l_approval_required <= 0 || (record.item.does_m_l_approval_required > 0 && record.item.manufacturer_approval_accepted_at))">
                                    <a @click="handleInTransitClick(record.item)"
                                       v-if="record.item.status >=4 && record.item.status <= 5"
                                       :title="$t('button.title.inTransit')"
                                       v-b-tooltip.hover class="ml-2 text-success">
                                        <i class="fe fe-truck"></i>
                                    </a>
                                </template>
                                <a
                                    v-if="record.item.status >=4 && record.item.status <= 5 && (record.item.does_m_l_approval_required > 0 && !record.item.manufacturer_approval_accepted_at)"
                                    :title="$t('button.title.waitingFcaApproval')"
                                    v-b-tooltip.hover
                                    class="ml-2 text-info">
                                    <i class="fe fe-truck"></i>
                                </a>
                                <a @click="handleUnLoadClick(record.item)"
                                   v-if="!record.item.is_d_unloaded && record.item.status >= 6"
                                   :title="$t('button.title.confirmUnload')" v-b-tooltip.hover
                                   class="ml-2 text-warning">
                                    <i class="fe fe-truck"></i>
                                </a>
                                <a :title="$t('button.title.requestToProceedUnload')"
                                   v-if="record.item.is_d_unloaded"
                                   class="ml-2 text-primary">
                                    <i class="fe fe-fast-forward" aria-hidden="true"></i>
                                </a>
                                <a @click="setOperation('detail', record.item.id)"
                                   :title="$t('button.title.detailItem')" class=" ml-2"
                                   v-b-tooltip.hover>
                                    <i class="icmn-info"></i>
                                </a>
                            </div>
                        </template>
                        <template v-slot:row-details="{ item }">
                            <b-card>
                                <b-list-group flush>
                                    <b-list-group-item></b-list-group-item>
                                </b-list-group>
                            </b-card>
                        </template>
                    </b-table><!-- /.b-table -->

                    <div class="clearfix">
                        <div class="float-left ">
                            <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]"
                                           size="sm"></b-form-select>
                        </div>
                        <div class="float-right">
                            <b-pagination
                                v-model="pagination.current"
                                :total-rows="pagination.total"
                                :per-page="pagination.perPage"
                                :first-text="$t('paginations.first')"
                                :prev-text="$t('paginations.prev')"
                                :next-text="$t('paginations.next')"
                                :last-text="$t('paginations.last')"
                            ></b-pagination>
                        </div><!-- /.pull-right -->
                    </div><!-- /.clearfix -->
                </div><!-- /.supplier-inbound-table-->
                <div class="supplier-inbound-operation">
                    <a-drawer
                        placement="right"
                        :width="'90%'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="handleOperationReset"
                        :visible="selectedLoad !== null"
                        :zIndex="10"
                    >
                        <template slot="title">
                            <span v-if="formFields.edit">
                                {{ (formFields.supplier_carrier_reconfirm_at ? $t('title.confirm') : $t('title.editInbound')) }}
                            </span>
                            <span v-if="!formFields.edit">
                                {{title}}
                            </span>
                            <span class="text-dark" v-if="selectedLoad">
                                <span class="badge badge-dark">
                                    <span v-if="selectedLoad.is_cc > 0">CC - </span>
                                    <span v-if="selectedLoad.is_spot > 0">{{$t('title.singlePickup')}} - </span>
                                    <strong>{{(selectedLoad || {}).load_number}}</strong>
                                </span>
                            </span>
                        </template>
                        <form @submit.prevent="handleSubmit" autocomplete="off">
                            <b-row class="mb-3">
                                <template v-if="formFields.status === 4">
                                    <b-col md="5" lg="5" sm="12">
                                        <b-row>
                                            <b-col md="6" lg="6" sm="12">
                                                <div class="form-group">
                                                    <div class="clearfix">
                                                        <span class="pull-left">
                                                            <label for="driver_id" class="d-block">
                                                                {{$t('input.driver')}} *
                                                            </label>
                                                        </span><!-- /.pull-left -->
                                                        <span class="pull-right">
                                                            <quick-driver-form
                                                                allow-update="1"
                                                                allow-create="1"
                                                                :id="formFields.driver_id"
                                                                :after-create="handleAfterQuickDriverCreated"
                                                                :after-update="handleAfterQuickDriverUpdated">
                                                            </quick-driver-form>
                                                        </span><!-- /.pull-right -->
                                                    </div><!-- /.clearfix -->
                                                    <treeselect
                                                        :multiple="false"
                                                        :options="dropdowns.drivers"
                                                        placeholder=""
                                                        v-model="formFields.driver_id"
                                                        :class="[{'invalid is-invalid': (formErrors.has('driver_id'))}]"
                                                    />
                                                    <div class="invalid-feedback">{{formErrors.first('driver_id')}}
                                                    </div>
                                                </div><!-- /.form-group -->
                                            </b-col>
                                            <b-col md="6" lg="6" sm="12">
                                                <div class="form-group">
                                                    <div class="clearfix">
                                                        <span class="pull-left">
                                                            <label for="transport_vehicle_id" class="d-block">
                                                                {{$t('input.vehicleRegistrationPlate')}} *
                                                            </label>
                                                        </span><!-- /.pull-left -->
                                                        <span class="pull-right">
                                                            <quick-transport-vehicle-form
                                                                allow-update="1"
                                                                allow-create="1"
                                                                :id="formFields.transport_vehicle_id"
                                                                :after-create="handleAfterQuickTransportVehicleCreated"
                                                                :after-update="handleAfterQuickTransportVehicleUpdated">
                                                            </quick-transport-vehicle-form>
                                                        </span><!-- /.pull-right -->
                                                    </div><!-- /.clearfix -->
                                                    <treeselect
                                                        :multiple="false"
                                                        :options="dropdowns.transportVehicles"
                                                        placeholder=""
                                                        v-model="formFields.transport_vehicle_id"
                                                        :class="[{'invalid is-invalid': (formErrors.has('transport_vehicle_id'))}]"
                                                    />
                                                    <div class="invalid-feedback">
                                                        {{formErrors.first('transport_vehicle_id')}}
                                                    </div>
                                                </div><!-- /.form-group -->
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                    <b-col md="7" lg="7" sm="12">
                                        <b-row>
                                            <b-col md="8" lg="8" sm="12">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <label class="eastimate-label">{{$t('input.estimatedLoadingTime')}}</label>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <b-form-group
                                                            :label="$t('input.from')+' *'"
                                                            label-for="from_estimated_loading_time"
                                                            :class="{'has-error': formErrors.has('from_estimated_loading_time')}">
                                                            <a-date-picker
                                                                :show-time="{ format: 'HH:mm' }"
                                                                placeholder=""
                                                                format="DD-MM-YYYY hh:mm A"
                                                                v-model="formFields.from_estimated_loading_time"
                                                                :disabled-date="keepDisabledDates"
                                                            />
                                                            <div class="invalid-feedback">
                                                                {{formErrors.first('from_estimated_loading_time')}}
                                                            </div>
                                                        </b-form-group>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <b-form-group
                                                            :label="$t('input.to')+' *'"
                                                            label-for="to_estimated_loading_time"
                                                            :class="{'has-error': formErrors.has('to_estimated_loading_time')}">
                                                            <a-date-picker
                                                                :show-time="{ format: 'HH:mm' }"
                                                                placeholder=""
                                                                format="DD-MM-YYYY hh:mm A"
                                                                v-model="formFields.to_estimated_loading_time"
                                                            />
                                                            <div class="invalid-feedback">
                                                                {{formErrors.first('to_estimated_loading_time')}}
                                                            </div>
                                                        </b-form-group>
                                                    </div>
                                                </div>
                                            </b-col>
                                            <b-col md="4" lg="4" sm="12">
                                                <b-form-group
                                                    :label="$t('input.estimatedUnloadingTime')"
                                                    label-for="estimatedUnloadingTime"
                                                    :class="{'has-error': formErrors.has('estimated_unloading_time')}">
                                                    <a-date-picker
                                                        :show-time="{ format: 'HH:mm' }"
                                                        placeholder=""
                                                        format="DD-MM-YYYY hh:mm A"
                                                        v-model="formFields.estimated_unloading_time"
                                                    />
                                                    <div class="invalid-feedback">
                                                        {{formErrors.first('estimated_unloading_time')}}
                                                    </div>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </template>
                                <template v-if="formFields.status >= 6">
                                    <b-col md="3" lg="3" sm="12">
                                        <b-form-group
                                            :label="$t('input.dateTime')"
                                            label-for="dateTime"
                                            :class="{'has-error': formErrors.has('created_at')}">
                                            <a-date-picker
                                                :show-time="{ format: 'HH:mm' }"
                                                placeholder=""
                                                format="DD-MM-YYYY hh:mm A"
                                                v-model="formFields.created_at"
                                                :class="[{'invalid is-invalid': (formErrors.has('created_at'))}]"
                                            />
                                            <div class="invalid-feedback">{{formErrors.first('created_at')}}</div>
                                        </b-form-group>
                                    </b-col>
                                </template>
                                <b-col md="9" lg="9" sm="12">
                                    <b-form-group
                                        :label="$t('input.comment')+(formFields.status === 3 ? ' *' : '')"
                                        label-for="comment"
                                        :invalid-feedback="formErrors.first('comments')">
                                        <b-textarea
                                            id="comment"
                                            v-model="formFields.comments"
                                            type="text"
                                            :state="((formErrors.has('comments') ? false : null))"
                                            @focus="$event.target.select()"
                                        ></b-textarea>
                                    </b-form-group>
                                </b-col>

                                <!-- documents -->
                                <b-col lg="12" md="12" sm="12" class="mb-5" v-if="formFields.status === 7">
                                    <b-card class="mb-0">
                                        <b-card-header v-b-toggle="'inbound-load-documents'" class="p-0">
                                            <h4 class="mb-0">
                                                <span class="badge badge-primary">
                                                    {{$t('title.attachments')}}
                                                </span>
                                                <small v-show="formFields.documents.length > 0">
                                                    - {{formFields.documents.length}} Item/s
                                                </small>
                                            </h4>
                                        </b-card-header><!-- /.p-0-->
                                        <b-collapse id="inbound-load-documents">
                                            <div class="bg-light p-3">
                                                <b-row>
                                                    <b-col lg="4" md="4" sm="12">
                                                        <div class="form-group">
                                                            <b-form-group
                                                                    :label="$t('input.title')+' *'"
                                                                    label-for="title"
                                                                    :invalid-feedback="formErrors.first('title')"
                                                            >
                                                                <b-form-input
                                                                        id="title"
                                                                        v-model="formFields.document.title"
                                                                        type="text"
                                                                        :state="((formErrors.has('title') ? false : null))"
                                                                        @focus="$event.target.select()"
                                                                ></b-form-input>
                                                            </b-form-group>
                                                            <div class="invalid-feedback">{{formErrors.first('title')}}</div>
                                                        </div><!-- /.form-group -->
                                                    </b-col><!--/b-col-->
                                                    <b-col lg="4" md="4" sm="12">
                                                        <b-form-group class="mt-4 pt-2">
                                                            <upload
                                                                    v-model="formFields.document.document"
                                                                    :disabled="formFields.document.document"
                                                                    :title="(formFields.document.document ? $t('msc.uploadedFile') : $t('msc.uploadFile'))"
                                                                    css-class="mt-0 btn-sm"></upload>
                                                            <b-button :title="$t('msc.removeUpload')"
                                                                      type="button"
                                                                      variant="outline-primary"
                                                                      v-b-tooltip.hover
                                                                      class="ml-2 ml-2 btn-sm"
                                                                      @click="() => {formFields.document.document = null;}"
                                                                      :disabled="!formFields.document.document"
                                                                      v-if="formFields.document.document">
                                                                <i class="fa fa-close"></i>
                                                            </b-button>
                                                            <b-button :title="$t('button.download')"
                                                                      v-b-tooltip.hover
                                                                      type="button"
                                                                      variant="outline-primary"
                                                                      class="ml-2 ml-2 btn-sm"
                                                                      v-if="formFields.document.document && formFields.document.document.download_url"
                                                                      :disabled="!(formFields.document.document && formFields.document.document.download_url)"
                                                                      :href="(formFields.document.document ? formFields.document.document.download_url : '')"
                                                                      target="_blank">
                                                                <i class="fa fa-cloud-download"></i>
                                                            </b-button>
                                                        </b-form-group>
                                                        <div class="invalid-feedback d-block">
                                                            {{formErrors.first('document')}}
                                                        </div>
                                                    </b-col><!--/b-col-->
                                                    <b-col lg="2" md="2" sm="12">
                                                        <div class="form-group">
                                                            <label class="d-block">
                                                                <pre> </pre>
                                                            </label>
                                                            <b-button variant="info" type="button" size="sm"
                                                                      class="btn-block"
                                                                      @click="handleAddUpdateDocumentClick()">
                                                                <i class="fa fa-plus"></i>
                                                            </b-button><!--/b-button-->
                                                        </div><!-- /.form-group -->
                                                    </b-col><!--/b-col-->
                                                    <b-col lg="2" md="2" sm="12">
                                                        <div class="form-group">
                                                            <label class="d-block">
                                                                <pre> </pre>
                                                            </label>
                                                            <b-button variant="warning" type="button" size="sm"
                                                                      class="btn-block"
                                                                      @click="resetDocument()">
                                                                {{$t('button.reset')}}
                                                            </b-button><!--/b-button-->
                                                        </div><!-- /.form-group -->
                                                    </b-col><!--/b-col-->
                                                </b-row><!--/b-row-->
                                                <b-row>
                                                    <b-col cols="12">
                                                        <table class="table table-bordered bg-white">
                                                            <thead>
                                                                <tr>
                                                                    <th width="50">#</th>
                                                                    <th width="180">{{$t('column.title')}}</th>
                                                                    <th width="60">{{$t('column.action')}}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(ct, I) in formFields.documents"
                                                                    :class="[{'table-primary': ct.token === formFields.document.token}]">
                                                                    <td>{{I + 1}}</td>
                                                                    <td>
                                                                        {{ct.title}}
                                                                    </td>
                                                                    <td>
                                                                        <a @click="handleEditDocumentClick(ct.token)"
                                                                           :title="$t('button.title.editItem')"
                                                                           v-b-tooltip.hover>
                                                                            <i class="fe fe-edit"></i>
                                                                        </a>
                                                                        <a :title="$t('button.download')" class=" ml-1"
                                                                           :href="ct.document.download_url"
                                                                           target="_blank" v-b-tooltip.hover>
                                                                            <i class="fa fa-cloud-download"></i>
                                                                        </a>
                                                                        <a-popconfirm title="Are you sure？"
                                                                                      @confirm="handleDeleteDocumentClick(ct.token)">
                                                                            <i slot="icon" class="fe fe-trash"></i>
                                                                            <a class=" ml-1"
                                                                               :title="$t('button.title.deleteItem')"
                                                                               v-b-tooltip.hover>
                                                                                <i class="fe fe-trash"></i>
                                                                            </a>
                                                                        </a-popconfirm>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot v-show="formFields.documents.length <= 0">
                                                                <tr>
                                                                    <th colspan="3">{{$t('title.noDataAvailable')}}</th>
                                                                </tr>
                                                            </tfoot>
                                                        </table><!-- /.table table-bordered -->
                                                    </b-col><!--/b-col-->
                                                </b-row><!--/b-row-->
                                            </div><!-- /.bg-light -->
                                        </b-collapse><!-- /#inbound-load-documents-->
                                    </b-card><!-- /b-card -->
                                </b-col><!--/b-col-->

                                <b-col sm="12" v-if="selectedLoad && selectedLoad.inbound_load_orders">
                                    <h4 class="text-secondary">{{$t('title.orders')}}</h4>
                                    <div class="table-responsive">
                                        <table class="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>{{$t('column.fromLocation')}}</th>
                                                    <th>{{$t('column.toLocation')}}</th>
                                                    <th>{{$t('column.orderId')}}</th>
                                                    <th v-if="selectedLoad.does_m_l_approval_required < 1 || selectedLoad.manufacturer_approval_accepted_at">
                                                        {{$t('column.vin')}}
                                                    </th>
                                                    <th v-if="selectedLoad.does_m_l_approval_required < 1 || selectedLoad.manufacturer_approval_accepted_at">
                                                        {{$t('column.brand')}}
                                                    </th>
                                                    <th v-if="selectedLoad.does_m_l_approval_required < 1 || selectedLoad.manufacturer_approval_accepted_at">
                                                        {{$t('column.model')}}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(ilo, index) in selectedLoad.inbound_load_orders">
                                                <td>{{index + 1}}</td>
                                                <td>
                                                    <span v-if="ilo.order.from_location">
                                                        <print-ad :item="ilo.order.from_location"></print-ad>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span v-if="ilo.order.to_location">
                                                        <print-ad :item="ilo.order.to_location"></print-ad>
                                                    </span>
                                                </td>
                                                <td>{{ilo.order.order_id}}</td>
                                                <td v-if="selectedLoad.does_m_l_approval_required < 1 || selectedLoad.manufacturer_approval_accepted_at">
                                                    {{ilo.order.vin_number}}
                                                </td>
                                                <td v-if="selectedLoad.does_m_l_approval_required < 1 || selectedLoad.manufacturer_approval_accepted_at">
                                                    {{ilo.order.brand.title}}
                                                </td>
                                                <td v-if="selectedLoad.does_m_l_approval_required < 1 || selectedLoad.manufacturer_approval_accepted_at">
                                                    {{ilo.order.model.title}}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-col>
                            </b-row>
                            <div class="drawer-footer">
                                <b-button variant="warning" @click="handleOperationReset" class="mr-2" :disabled="global.pendingRequests > 0"
                                          size="sm" :title="$t('button.title.cancel')" v-b-tooltip.hover>
                                    {{$t('button.cancel')}}
                                </b-button>
                                <b-button variant="primary" button="submit" type="filled"
                                          :disabled="global.pendingRequests > 0" size="sm" class="mr-2"
                                          :title="$t('button.title.update')" v-b-tooltip.hover>
                                    <clip-loader style="display: inline" :loading="true" color="#fff"
                                                 size="12px"
                                                 v-if="global.pendingRequests > 0"></clip-loader>
                                    {{$t('button.update')}}
                                </b-button>
                            </div><!-- /.drawer-footer -->
                        </form>
                    </a-drawer>
                </div><!--/.supplier-inbound-operation-->
                <div class="filter-container">
                    <a-drawer
                        placement="left"
                        :width="'360px'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="filters.visible = !filters.visible"
                        :visible="!operation && filters.visible"
                        :zIndex="10"
                        :title="$t('title.advanceFilters')"
                    >
                        <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                            <b-row>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.compounds')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.supplierCompounds"
                                            placeholder=""
                                            v-model="filters.supplierCompounds"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.brands')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.brands"
                                            placeholder=""
                                            v-model="filters.brands"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.models')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.models"
                                            placeholder=""
                                            v-model="filters.models"
                                            @input="handleSelectModelsSelect"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.code')">
                                        <treeselect
                                            :multiple="true"
                                            :options=" _.uniqBy(_.filter(dropdowns.configCode, ({ model_id }) => _.every([_.includes(filters.models, model_id)])),'id')"
                                            placeholder=""
                                            v-model="filters.configCode"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.loadingPoint')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.locations"
                                            placeholder=""
                                            v-model="filters.loadingPoint"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.unloadingPoint')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.locations"
                                            placeholder=""
                                            v-model="filters.unloadingPoint"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.drivers')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.drivers"
                                            placeholder=""
                                            v-model="filters.drivers"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.vehicles')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.vehicles"
                                            placeholder=""
                                            v-model="filters.vehicles"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.status')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.status"
                                            placeholder=""
                                            v-model="filters.status"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group :label="$t('input.fromAddedDate')">
                                        <b-form-datepicker placeholder="" v-model="filters.from_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group :label="$t('input.toAddedDate')">
                                        <b-form-datepicker placeholder="" v-model="filters.to_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </b-row>
                            <div class="drawer-footer">
                                <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                          class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                    {{$t('button.close')}}
                                </b-button>
                                <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                          :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                    {{$t('button.reset')}}
                                </b-button>
                                <b-button size='sm' variant="primary" button="submit" type="filled"
                                          :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                    {{$t('button.apply')}}
                                </b-button>
                            </div><!-- /.drawer-footer -->
                        </form>
                    </a-drawer>
                </div><!-- /.filter-container -->
            </div><!-- /.card-body-->
        </div><!-- /.card -->
        <div v-if="operation === 'detail'">
            <detail :handle-close-operation="handleOperationClose"></detail>
        </div>

        <!-- # Excel Export -->
        <b-modal id="excel-export-modal" v-model="exportModal.visibility" hide-footer>
            <template #modal-title>
                {{$t('title.exportExcel')}}
            </template>
            <div>
                <form @submit.prevent="handleExcelExportSubmitClick">
                    <div class="mb-5">
                        <span v-for="(column, index) in exportModal.columns" class="m-1 d-inline-block">
                            <b-form-checkbox v-model="column.show" name="check-button"
                                             button>{{column.label}}</b-form-checkbox>
                        </span>
                        <hr>
                        <b-form-group>
                            <a href="javascript:;" class="p-3" @click="handleExcelExportSelectAllClick">{{$t('input.checkAll')}}</a>
                            <a href="javascript:;" class="p-3" @click="handleExcelExportUnSelectAllClick">{{$t('input.unCheckAll')}}</a>
                        </b-form-group>
                    </div>
                    <div class="drawer-footer">
                        <json-excel
                            class="btn btn-primary btn-sm"
                            :fields="exportModal.headers"
                            worksheet="transportOrders"
                            :fetch="handleExcelExportSubmitClick"
                            name="tranport_orders.xls">
                            <clip-loader style="display: inline" :loading="true" color="#fff"
                                         size="12px" v-if="global.pendingRequests > 0"></clip-loader>
                            <i class="fa fa-save mr-1"></i>
                            {{$t('button.export')}}
                        </json-excel>
                        <b-button variant="warning" class="ml-3"
                                  size="sm" @click="handleExcelExportCancelClick()"
                                  v-b-tooltip.hover :title="$t('button.title.cancel')">
                            <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                        </b-button>
                    </div>
                </form>
            </div>
        </b-modal><!--/#excel-export-modal-->
    </div>
</template>
<script>
    import ListingMixin from '../../../util/ListingMixin'
    import Error from '../../../util/Error'
    import {mapState} from 'vuex'
    import Datepicker from 'vuejs-datepicker'
    import {request} from '../../../util/Request'
    import Treeselect from '@riophae/vue-treeselect'
    import StatusUpdateMixin from './StatusUpdateMixin'
    import QuickDriverForm from "./../../drivers/QuickDriverForm"
    import QuickTransportVehicleForm from "./../../transportVehicles/QuickTransportVehicleForm"
    import Detail from './detail'
    import _ from "lodash";
    import moment from "moment-timezone";
    import ExcelExportMixin from "./ExcelExportMixin";
    import JsonExcel from "vue-json-excel"

    const FORM_STATE = {
        id: null,
        driver_id: null,
        edit: 0,
        transport_vehicle_id: null,
        comments: null,
        status: null,
        estimated_unloading_time: null,
        from_estimated_loading_time: null,
        to_estimated_loading_time: null,
        created_at: null,
        documents: [],
        _method: 'post',
    };

    const FILTER_STATE = {
        visible: false,
        from_date: null,
        to_date: null,
        brands: [],
        models: [],
        configCode: [],
        drivers: [],
        vehicles: [],
        status:[],
        loadingPoint:[],
        unloadingPoint:[],
        supplierCompounds: [],
    };

    const COLUMN_DEFINITION = (self) => [
        {
            label: self.$t('column.loadNumber'),
            key: 'load_number',
            sortable: true,
        },
        {
            label: self.$t('column.supplierCompound'),
            key: 'supplier_compound_id',
            sortable: true,
        },
        {
            label: self.$t('column.fromLocation'),
            key: 'from_location',
            sortable: false,
        },
        {
            label: self.$t('column.toLocation'),
            key: 'to_location',
            sortable: false,
        },
        {
            label: self.$t('column.transportVehicle'),
            key: 'transport_vehicle_id',
            sortable: true,
        },
        {
            label: self.$t('column.driver'),
            key: 'driver_id',
            sortable: true,
        },
        {
            label: self.$t('column.numberOfOrders'),
            key: 'orders',
            sortable: false,
        },
        {
            label: self.$t('column.status'),
            key: 'status',
            sortable: true,
        },
        (self.$global.hasAnyPermission(['inboundloadsownview'])
            ? {
                label: self.$t('column.action'),
                class: 'text-right',
                key: 'action',
                width: 150,
            } : {}),
    ];

    export default {
        mixins: [ListingMixin, StatusUpdateMixin, ExcelExportMixin],
        components: {
            Treeselect,
            Detail,
            QuickDriverForm,
            QuickTransportVehicleForm,
            JsonExcel,
        },
        data() {
            return {
                operationTitle: 'title.inboundLoad',
                formFields: {...FORM_STATE},
                filters: {...FILTER_STATE},
                listUrl: 'inbound/loads/carrier/supplier',
                columns: COLUMN_DEFINITION(this),
                dropdowns: {
                    transportVehicles: [],
                    suppliers: [],
                    orders: [],
                    countries: [],
                    brands: [],
                    models: [],
                    configCode: [],
                    drivers: [],
                    vehicles: [],
                    status: [
                        {id: 1, label: this.$t('title.created')},
                        {id: 2, label: this.$t('title.announced')},
                        {id: 3, label: this.$t('title.rejected')},
                        {id: 4, label: this.$t('title.confirmed')},
                        {id: 5, label: this.$t('title.loaded')},
                        {id: 6, label: this.$t('title.inTransit')},
                        {id: 7, label: this.$t('title.unloaded')},
                        {id: 8, label: this.$t('title.unloadedCompound')},
                    ],
                    locations:[],
                    supplierCompounds: [],
                },
                show: true,
                selectedLoad: null,
            }
        },
        mounted() {
            this.getTransportVehicles();
            this.getDrivers();
            this.getBrands();
            this.getModels();
            this.getLocations();
            this.getCountries();
            this.getSupplierCompounds();
            this.$title = this.$t('topBar.navigations.modules.inbound')
        },
        methods: {
            toggleDetails(row) {
                this.$set(row, '_showDetails', ((row._showDetails) ? !row._showDetails : true))
            },
            setOperation(operation = 'add', operationToken = null) {
                this.operationTitle = (operation === 'add' ? 'title.addOrder' : 'title.editOrder')
                this.$router.replace({
                    query: Object.assign({},
                        this.$route.query,
                        {
                            ...this.listQueryParams,
                            operation: operation,
                            oToken: operationToken,
                        },
                    ),
                }).then(() => {
                }).catch(() => {
                })
            },
            async handleSubmit() {
                this.formErrors = new Error({})
                const {estimated_unloading_time, from_estimated_loading_time, to_estimated_loading_time, created_at} = this.formFields
                try {
                    const response = await request({
                        url: '/inbound/loads/carrier/supplier/update',
                        method: 'post',
                        data: {
                            ...this.formFields,
                            created_at: ((created_at) ? this.$global.dateToUtcDate(this.formFields.created_at, 'YYYY-MM-DD hh:mm A', 'YYYY-MM-DD HH:mm:ss') : null),
                            estimated_unloading_time: ((estimated_unloading_time) ? this.$global.dateToUtcDate(estimated_unloading_time.format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD hh:mm A', 'YYYY-MM-DD HH:mm:ss') : null),
                            from_estimated_loading_time: ((from_estimated_loading_time) ? this.$global.dateToUtcDate(from_estimated_loading_time.format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD hh:mm A', 'YYYY-MM-DD HH:mm:ss') : null),
                            to_estimated_loading_time: ((to_estimated_loading_time) ? this.$global.dateToUtcDate(to_estimated_loading_time.format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD hh:mm A', 'YYYY-MM-DD HH:mm:ss') : null),
                        },
                    })

                    this.itemUpdated()
                    this.handleOperationReset()
                    this.loadList()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }

                    this.handleServerError(error)
                }
            },
            handleOperationReset() {
                this.formFields = {...FORM_STATE}
                this.selectedLoad = null
            },
            hasListAccess() {
                return this.$global.hasPermission('inboundloadsownview')
            },
            async getCountries() {
                try {
                    const response = await request({
                        url: '/dropdowns/countries',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.countries = data

                } catch (e) {
                    this.dropdowns.countries = []
                }
            },
            async getBrands() {
                try {
                    const response = await request({
                        url: '/dropdowns/brands',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.brands = data

                } catch (e) {
                    this.dropdowns.brands = []
                }
            },
            async getModels() {
                try {
                    const response = await request({
                        url: '/dropdowns/models',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.models = data.map(item => ({id: item.id, label: `${item.label}`}))
                    this.dropdowns.configCode = data.map(item => ({
                        id: item.version_code,
                        label: item.version_code,
                        model_id: item.id
                    }))
                } catch (e) {
                    this.dropdowns.models = []
                    this.dropdowns.configCode = []
                }
            },
            async getDrivers() {
                try {
                    const response = await request({
                        url: '/dropdowns/drivers',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.drivers = data
                } catch (e) {
                    this.dropdowns.drivers = []
                }
            },
            async getTransportVehicles() {
                try {
                    const response = await request({
                        url: '/dropdowns/transport/vehicles',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.vehicles = data
                    this.dropdowns.transportVehicles = data
                } catch (e) {
                    this.dropdowns.vehicles = []
                    this.dropdowns.transportVehicles = []
                }
            },
            async getLocations() {
                try {
                    const response = await request({
                        url: '/dropdowns/locations',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.locations = _.map(data,(i) => {
                        return{
                            id:i.id,
                            label:i.city,
                        }
                    })
                } catch (e) {
                    this.dropdowns.locations = []
                }
            },
            async getSupplierCompounds() {
                try {
                    const response = await request({
                        url: '/dropdowns/suppliers/compound',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.supplierCompounds = data

                } catch (e) {
                    this.dropdowns.supplierCompounds = []
                }
            },
            handleSelectModelsSelect(changeValue) {
                this.filters.configCode = []
            },
            getExtraParams() {
                return {
                    filters: {
                        ...this.filters,
                        from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    },
                }
            },
            handleResetFilterClick() {
                this.filters = {...FILTER_STATE}
                this.isFilterApplied = 'reset'
                this.handleResetClick()
                this.loadList(this.listQueryParams)
            },
            afterCloseOperation() {
                this.formFields = {...FORM_STATE}
            },
            handleAfterQuickDriverCreated(inputs) {
                this.dropdowns.drivers.push({id: inputs.id, label: inputs.name})
                this.formFields.driver_id = inputs.id;
            },
            handleAfterQuickDriverUpdated(inputs) {
                const index = _.findIndex(this.dropdowns.drivers, {id: inputs.id})
                this.$set(this.dropdowns.drivers[index], 'label', `${inputs.name}`);
            },
            handleAfterQuickTransportVehicleCreated(inputs) {
                this.dropdowns.transportVehicles.push({
                    id: inputs.id,
                    label: inputs.brand + ', ' + inputs.truck_plate_number + " | " + inputs.trailer_plate_number
                })
                this.formFields.transport_vehicle_id = inputs.id;
            },
            handleAfterQuickTransportVehicleUpdated(inputs) {
                const index = _.findIndex(this.dropdowns.transportVehicles, {id: inputs.id})
                this.$set(this.dropdowns.transportVehicles[index], 'label', `${inputs.brand + ', ' + inputs.truck_plate_number + " | " + inputs.trailer_plate_number}`);
            },
            keepDisabledDates(current) {
                if(this.selectedLoad && this.selectedLoad.inbound_delay && this.selectedLoad.inbound_delay > 0 && this.selectedLoad.is_cc <= 0) {
                    const delay = ((this.selectedLoad.inbound_delay == 1) ? 1 : (this.selectedLoad.inbound_delay - 1));
                    let created_at = moment(this.selectedLoad.created_at).add(parseInt(delay), 'day');
                    return current && current < created_at.clone().tz(moment.tz.guess()).clone().endOf('day');
                }

                return false;
            },
            rowClass(item, type) {
                if (!item || type !== 'row') return
                if (item.is_cc > 0) return 'bl-2 border-dark'
                if (item.is_spot > 0) return 'bl-3 border-warning'
            }
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
<style lang="scss">
    .w-80 {
        width: 80px;
    }

    .in-center {
        position: relative;
        top: 1px;
        margin-right: 8px;
    }

    .order-select-chbx {
        position: absolute;
        right: -7px;
        top: -7px;
    }

    .add-load-btn {
        position: absolute;
        top: 12px;
        right: 50px;
    }

    @media screen and (max-width: 768px) {
        .ant-drawer-content-wrapper {
            width: 98% !important;
        }
    }

    [data-kit-theme="default"] .ant-input {
        height: 35px;
    }

    .ant-calendar-picker {
        width: 100%;
    }

    [data-kit-theme="default"] .ant-form-item-label {
        line-height: 26px !important;
    }

    .has-error .invalid-feedback {
        display: block;
    }

    .eastimate-label{
        margin: 0;
        font-size: 10px;
        position: absolute;
        top: -16px;
    }
</style>
